<script>
import { Notify, Dialog, Icon } from "vant";

const noop = () => {};

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    stylish: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    confirmLabel: {
      type: String,
      default: () => {
        return "确认";
      },
    },
    cancelLabel: {
      type: String,
      default: () => {
        return "取消";
      },
    },
    hideCancelButton: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      visible: false,
      resolvers: {
        resolve: noop,
        reject: noop,
      },
    };
  },
  methods: {
    handleConfirm() {
      this.resolvers.resolve({
        close: () => {
          this.close();
        },
      });
    },

    handleCancel() {
      this.resolvers.reject({
        close: () => {
          this.close();
        },
      });
    },

    close() {
      this.resolvers.resolve = noop;
      this.resolvers.reject = noop;
      this.visible = false;
    },

    open(resolve, reject) {
      this.resolvers.resolve = resolve || this.close;
      this.resolvers.reject = reject || this.close;
      this.visible = true;
    },
  },
};
</script>

<template>
  <van-dialog
    v-model="visible"
    :showConfirmButton="false"
    :className="['dialog', stylish && 'dialog__stylish']"
  >
    <div class="title" v-if="this.title">
      {{ this.title }}
    </div>
    <div class="body">
      <slot />
    </div>
    <div
      class="footer"
      :style="hideCancelButton ? 'justify-content: center;' : ''"
    >
      <button
        v-if="!hideCancelButton"
        class="footer_button cancel"
        @click="handleCancel"
      >
        {{ cancelLabel }}
      </button>
      <button class="footer_button confirm" @click="handleConfirm">
        {{ confirmLabel }}
      </button>
    </div>
  </van-dialog>
</template>

<style scoped lang="less">
.dialog {
  border-radius: 20px;
  width: calc(100vw - 40px);
  max-width: 360px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &.dialog__stylish {
    padding-top: 20px + 16px;
    &::before {
      content: "";
      height: 16px;
      background-color: #f67d53;
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    color: #435057;
    width: 100%;
    margin-bottom: 22px;
  }

  .body {
    color: #435057;
    width: 100%;
    min-height: 70px;
  }

  .footer {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .footer_button {
      width: 220px;
      height: 32px;
      border-radius: 8px;
      border: none;
      background: linear-gradient(0deg, #888888, #a6a6a6);
      color: #ffffff;

      &.confirm {
        background: linear-gradient(0deg, #ff6646, #ff8656);
      }
    }
  }
}
</style>
