import { Notify } from "vant";

import "./notify.css"

export const notify = {
  error: (message) => {
    return Notify({ message, type: "danger", className: "notify notify-error" });
  },
  warning: (message) => {
    return Notify({ message, type: "warning", className: "notify notify-warning" });
  },
  success: (message) => {
    return Notify({ message, type: "success", className: "notify notify-success" });
  },
};
